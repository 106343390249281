import { $freeDelete, $freeGet, $freePost } from '@js/request'
import { CourseDetailSchema } from '@component/schema/courseSchema'

export async function getRequest (uid: string, token: string, id: string): Promise<CourseDetailSchema> {
  const res = await $freeGet(`/api/xapc/course/${id}`, {
    uid
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}

export async function delRequest (uid: string, token: string, id: string): Promise<void> {
  const res = await $freeDelete(`/api/xapc/mediaComment/${id}`, {
    uid
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}

export async function addRequest (uid: string, token: string, mediaid: string, content: string): Promise<void> {
  const res = await $freePost('/api/xapc/mediaComment', {
    mediaid,
    content,
    uid
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}

export async function recordRequest (uid: string, token: string, mediaid: string, b: number, t: number): Promise<void> {
  const res = await $freePost('/api/xapc/mediaLog', {
    mediaid,
    begin_datetime: b,
    times: t,
    uid
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}

export async function getLicenseUrlRequest (): Promise<string> {
  const res = await $freeGet('/api/common/bizconfig/getMediaLicenseUrl')
  return res.data
}
